<template>
  <div id="TopBar" :class="scrollTop > 40 ? 'topBarAct' : ''">
    <div class="location">
      <slot name="leftInfo" :class="scrollTop > 40 ? 'fontColor' : ''"> </slot>
    </div>

    <div class="searchBar">
      <slot name="middleInfo"> </slot>
    </div>
    <div class="newMessage">
      <slot name="rightInfo"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollTop: "",
    };
  },
  mounted() {
    document.addEventListener("scroll", () => {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrolltop;
      this.$emit("topBarAct", scrolltop);
      // console.log(this.scrollTop)
    });
  },
};
</script>
<style lang="less">
#TopBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  font-size: 1.8rem;
  padding: 0 20px;
  &.topBarAct {
    background: #ff816c;
    color: #fff;
  }
  &.background {
    background: #ff816c;
    color: #fff;
  }
  .searchBar {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      border: none;
      width: 100%;
      border-radius: 25px;
      background: #f5f5f5;
      height: 25px;
      text-indent: 1rem;
      font-size: 1.4rem;
    }
    img {
      width: 60%;
    }
  }
  .location {
    width: 20%;
    display: flex;
    .fontColor {
      color: #fff;
    }
  }
  .newMessage {
    font-size: 1.4rem;
    width: 20%;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    img {
      width: 2.4rem;
    }
  }
}
</style>