<template>
  <div class="orderListPublic">
    <div class="itemList" @click="goAlreadyPage(listPublicObj)">
      <div class="itemTitle">
        <van-tag type="primary" size="large" plain :color="stateObj[listPublicObj.work_status].color">{{stateObj[listPublicObj.work_status].state}}</van-tag>
        <span class="type">{{listPublicObj.statusType}}</span>
        <span class="date" v-if="listPublicObj.refund_status == 1">请先处理退款，再预约客户</span>
        <span class="date" v-else>&nbsp;&nbsp;{{listPublicObj.updated_at}}&nbsp;报价</span>
      </div>
      <div class="itemInfo">
        <div class="itemBac" :style="`background: url('${cdnHttp}${listPublicObj.product_images.split(',')[0]}?imageView2/1/format/webp/w/110/h/110/q/90') no-repeat center / cover`"></div>
        <ul>
          <li>
            <span class="publicDes"> {{listPublicObj.service_mode_name}}</span>
            <span>
              <van-tag type="primary" color="#FF816C">{{ listPublicObj.category_name }}</van-tag>
            </span>
          </li>
          <li>
            <span class="publicDes line-over-hidden ">
              {{`${listPublicObj.client_name} | ${listPublicObj.full_address}`}}
            </span>
          </li>
          <li>
            <p v-if="listPublicObj.price"><span class="publicDes">报价金额 {{listPublicObj.price}}元</span></p>
            <p v-else><span class="publicDes">联系电话 {{listPublicObj.client_mobile}}</span></p>
          </li>
        </ul>
      </div>
      <div v-if="listPublicObj.work_status == 9">
        <p class="inspection_fail_reason" v-for="(item,index) in listPublicObj.inspections" :key="index">
          第{{index + 1}}验货异常原因: {{item.reason}}
        </p>
      </div>
      <div v-else-if="listPublicObj.work_status == 2">
        <p class="inspection_fail_sign">
          {{}}
        </p>
      </div>
      <div class="operation_tag">
        <van-tag type="primary" size="large" plain color="orange" @click.stop="goSubPage('refund',listPublicObj.id)" v-if=" listPublicObj.refund_status && listPublicObj.refund_status!= 0 && listPublicObj.refund_status != -1">
          {{refund_states.find(item => item.status == listPublicObj.refund_status).message}}
        </van-tag>
        <van-tag type="primary" size="large" plain color="orange" v-if="listPublicObj.work_status == 2">
          再次预约
        </van-tag>
        <van-tag type="primary" size="large" plain color="orange" v-if="listPublicObj.work_status == 9">
          再次签到
        </van-tag>
      </div>
      <!-- <div class="againButton" :style="`background:${stateObj[listPublicObj.state].color}`" v-if="stateObj[listPublicObj.work_status].again">
        <span>{{stateObj[listPublicObj.work_status].again}}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listPublicObj: {
      type: Object,
      default: {},
    },
    toPath: "",
    toPageName: "",
    toPageId: "",
    listState: "",
  },
  data() {
    return {
      stateObj: {
        0: { color: "#009051", state: "商家已下单" },
        1: { color: "#008F00", state: "待预约" },
        2: {
          color: "red",
          state: "预约失败",
          again: "再次预约",
          toName: "reservation",
        },
        3: { color: "#941100", state: "预约成功" },
        4: { color: "#FF7E79", state: "待提货" },
        5: { color: "#919191", state: "提货失败" },
        6: { color: "#919191", state: "提货成功,待签到" },
        7: { color: "#919191", state: "签到失败" },
        8: { color: "#919191", state: "已签到/待验货" },
        9: {
          color: "red",
          state: "验货异常",
          again: "再次验货",
          toName: "inspection",
        },
        10: { color: "#919191", state: "验货成功 / 待完成" },
        11: { color: "#919191", state: "完工失败" },
        12: { color: "#919191", state: "已确认完成/待验收" },
        13: { color: "#919191", state: "验收失败" },
        14: { color: "#919191", state: "验收成功" },
        15: { color: "#919191", state: "待退款" },
        16: { color: "#919191", state: "退款成功" },
        17: { color: "#919191", state: "交易成功" },
        '-1': { color: "#919191", state: "交易关闭" },
      },
    };
  },
  created() {
    console.log("订单列表的数据", this.listPublicObj);
  },
  methods: {
    goAlreadyPage(items) {
      console.log(items);
      if (items.work_status == 9) {
        this.goSubPage("inspection", items.id);
      } else if (items.work_status == 2) {
        this.goSubPage("reservation", items.id);
      } else {
        this.goSubPage(this.toPageName, items.id);
      }
    },
  },
};
</script>
<style lang="less">
.orderListPublic {
  margin: 0 0.8rem;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  .itemList {
    padding: 1rem;
    background: #fff;
    margin: 0.7rem 0;
    position: relative;
    border-radius: 10px;
    .againButton {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      width: 8rem;
      border: 1px solid #ff976a;
      background: #ff976a;
      border-radius: 10rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -2rem;
      padding: 0.25rem 0;
      font-size: 1.25rem;
    }
    .itemTitle {
      margin-bottom: 0.5rem;
      padding: 0.6rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f2f4f7;
      .type {
        font-size: 1.5rem;
        margin-left: -6rem;
      }
      .date {
        font-size: 1.3rem;
        color: #aaaaab;
      }
    }
    .inspection_fail_reason,
    .inspection_fail_sign {
      margin: 0 0.5rem;
      font-size: 1.3rem;
      background: #fffbe8;
      color: #ed6a0c;
      padding: 0.1rem 0;
    }
    .itemInfo {
      padding: 0.4rem 0;
      font-size: 1.35rem;
      display: flex;
      // border-bottom: 1px solid #f2f4f7;
      .itemBac {
        width: 10rem;
        height: 10rem;
        background: chocolate;
        border-radius: 8px;
      }
      ul {
        padding-left: 0.5rem;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        li {
          display: flex;
          .publicMenu {
            color: #a7a7a7;
            width: 22%;
            white-space: nowrap;
          }
          .publicDes {
            margin: 0 1rem 0 1rem;
            &.line-over-hidden {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    .operation_tag {
      padding: 0.4rem 0 0 0;
      display: flex;
      justify-content: flex-end;
    }
  }
  .act-list {
    border: 1px solid red;
  }
}
</style>