<template>
  <div id="reservation">
    <van-nav-bar title="待完成订单" left-arrow @click-left="goLastPage" :border="false" />
    <div class="reservationWrap">
      <span class="tip" v-if="willCompleteOrderList.length == 0? true: false">还没有待完工订单哦</span>
      <span class="tip" v-else>师傅继续努力吧</span>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <OrderListPublic v-for="(item,i) in willCompleteOrderList" :key="i" :listPublicObj="item" :toPageId="item.id" toPageName="finish_detail" />
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import OrderListPublic from "@/components/order/OrderListPublic.vue";
import TopBar from "@/components/home/TopBar.vue";
import { Toast } from "vant";
export default {
  components: {
    OrderListPublic,
    TopBar,
  },
  data() {
    return {
      willCompleteOrderList: [],
      isLoading: true,
      reservationID: 0,
    };
  },
  created() {
    this.getCompleteList();
  },
  methods: {
    goLastPage() {
      this.$router.go(-1);
    },
    getCompleteList() {
      this.$http
        .get("/api/v2/worker/orders/service?work_status=10.11")
        .then((res) => {
          console.log(res);
          this.willCompleteOrderList = res.data.result.data;
        });
    },
    onRefresh() {
      this.getCompleteList();
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>
<style lang="less">
#reservation {
  .tip {
    margin-top: 45px;
    font-size: 1.4rem;
    padding: 1rem 0;
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
</style>